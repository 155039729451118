import ArrowIcon from '../../Common/ui-components/ArrowIcon';
const MoreButton = ({expand,MoreButtonClick,ShowLessText,More})=>{
    return (
        <div
            className="sgaMoreButton"
            onClick={MoreButtonClick}
        >
            <div>{expand ? ShowLessText : More}</div>
            <ArrowIcon color="green" className="" direction={expand ? 'up' : 'down'} size={24} />
        </div>)
}

export default  MoreButton
import React, { useEffect, useState, createContext, useRef, useContext, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import TopMenu from '../Menu/TopMenu';
import LeftMenu from '../Menu/LeftMenu';
import Banner from '../Header/Banner';
import ImportantNotice from '../Header/ImportantNotice';

import FootballTitle from '../Football/Common/FootballTitle';
import AllupCalculator from '../Football/Common/AllupCalculator';
import DateTournSelection from '../Football/Common/DateTournSelection';
import PageRoute from '../Football/Common/PageRoute';
import MixAllupController from '../Football/Common/MixAllupController';
import FbBottomBetslipSticky from '../Football/Common/FbBottomBetslipSticky';
import AddSlipBtn from '../BetSlip/AddSlipBtn'

import '../../css/football/layout.scss';
import { GlobalContext } from '../../contexts/GlobalContext';

import { isLogonHigh } from '../BetSlip/LoginHooks';
import { isTournamentPage, isPoolDefined, isEarlySettlementEanbled, isInplayPage, isMatchResultPage, isPmPoolResultPage, isTournResultPage } from '../Football/Common/Common';
import { useWindowSize } from '../Common/CommonHooks';
import { getPersonalSettings } from '../Home/PersonalSettings/config'
import { WebTVIcon } from '../Football/Common/MatchBase';
import useFBDataRefresh from '../Football/Data/useFBDataRefresh';
import useGetMatches from '../Football/Data/useGetMatches';
import { getDisplayMatches } from '../Football/Data/useGetMatchList/common';
import useFootballPush from '../Football/Data/useFootballPush';
import { useFBSearch } from '../Football/Common/FBSearchHooks'
import { AllOddsMobileMatchInfo } from '../Football/Page/ALLODDS';
import { useWebTVEnabledMatchesQuery } from './Data/DataRefreshHooks';
import { useFBImportantNoticesQuery } from './Data/SitecoreDataHooks';
import { useFootballPageFunc } from './FootballPage/useFootballPageFunc';
import { getFootballTopics } from '../Football/Data/useFootballPush/common';
import { getQueryMatchIdForDateTab,
    getQueryMatchIdForTournTab, getQueryTorunIds} from '../Football/Common/Common'
import  MoblieHomePageTooltips from '../Common/ui-components/MobileHomePageTooltips'

import {Loading, Toast} from '../Common/ui-components';
import Sticky from 'react-stickynode';
import useIntersectionObserver from '../Common/hooks/useIntersectionObserver';
import { scrollTo } from '../Common/GlobalFunc';

export const FootballContext = createContext();

const FootballPage = (props) => {
    const globalContext = useContext(GlobalContext);
    const curProduct = 'football';
    const { lang, page, matchId } = useParams();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const contentRef = useRef({
        betType: 'HAD'
        , offset: 0
        , rawTournList: []
        , rawMatchList: []
        , listData: null
        , matchData: null
        , tournData: null
        , page: page?.toUpperCase()
        , selection: { type: 'ALL', tabs: [], value: [] }
        , oddsSelection: []
        , pageNum: 1
        , isLoading: false
        , isApiErr: false
        , update: null
        , allupSelection: []
        , allupCalData: null
        , isAllUpDtls: false
        , mixAllUpUnitBet: parseInt(getPersonalSettings('football', 'ALUP'), 10)
        , mixAllUpFormula: ''
        , isSearching: false
        , config : window.fbConfig
        , autoRefreshIntervalId: null
        , pushRefreshIntervalId: null
        , pauseManualRefresh: false
        , tournNameProfileId: null
        , fromES: false
        , esGetOpenbet: true
    });
    const [content, setContent] = useState(contentRef.current);

    const fbSearchHooks = useFBSearch(contentRef, setContent, content);
    const refreshData = useFBDataRefresh({contentRef, setContent, content, fbSearchHooks});
    const pushData = useFootballPush(contentRef, setContent);
    const { handleSelectionChange,
        handleAllupNextBtnClick,
        handleOnClickSearchResult } = useFootballPageFunc({contentRef, setContent, globalContext, refreshData});
    const { loadMatchQuery } = useGetMatches(contentRef, setContent)
    const [ loadMoreLoading, setLoadMoreLoading] = useState(false)
    const [tickToCalc, setTickToCalc] = useState(0)

    const fbOddsTableRef = useRef();
    const esPageRefreshRef = useRef({
        esIntervalFunc: null
    })
    const windowSize = useWindowSize();
    const webTVData = useWebTVEnabledMatchesQuery();
    const { betTypeMessages } = useFBImportantNoticesQuery();

    const tournIdsCacheRef = useRef(null);
    const [openTournIdsCache, setOpenTournIdsCache] = useState(false);

    //  https://github.com/apollographql/apollo-client/issues/9755
    //  use useApolloClient instead to useLazyQuery

    useEffect(()=>{
        if(!openTournIdsCache && tournIdsCacheRef.current){
            handleSelectionChange('TOURN', tournIdsCacheRef.current, null)
            tournIdsCacheRef.current = null
        }
    },[openTournIdsCache])

    //load common control webTV
    useEffect(()=> {
        if ( isLogonHigh() && contentRef.current.page=="INPLAY") {
            webTVData.loadQuery();
        }
    }, [isLogonHigh(), contentRef.current.page]);

    const resetDateTournTab = (contentRef) => {
        if (["OFM", "CASHOUT"].includes(contentRef.current.page) || isTournamentPage(contentRef.current.page)) {
            fbSearchHooks.clearSearchText()
            contentRef.current.selection = {
                type: 'ALL',
                tabs: [],
                value: []
            }
        } else if (contentRef.current.selection.type == '' || contentRef.current.selection.type == 'ALL' || (contentRef.current.page !== 'MIXALLUP' && contentRef.current.selection.type === 'OFM')) {
            contentRef.current.selection = {
                type: contentRef.current.selection.type == "DATE" ? "DATE" :'ALL',
                tabs: [],
                value: contentRef.current.selection.type == "DATE" ? contentRef.current.selection.value : []
            }

            if ( matchId?.startsWith('t') ) {
                contentRef.current.selection = {
                    type: 'TOURN',
                    tabs: [],
                    value: []
                }
            }
        }else if(contentRef.current.selection.type === 'SEARCH' && !contentRef.current.pageConfig.showSearchTab){
            fbSearchHooks.clearSearchText()
            contentRef.current.selection = {
                type: 'ALL',
                tabs: [],
                value: []
            }
        }
    }

    useEffect(()=>{
        let hash = ''
        let hlTournMatchMenu  = sessionStorage.getItem('hlTournMatchMenu')
        const type = contentRef.current.selection.type
        if(type === 'ALL' ) {
            hash = ''
        }
        else if(type === 'SEARCH'){
            hash = ''
        }
        else if(type === 'TOURN' && contentRef.current.page!=="TOURNAMENT") {
            let curProfileIds = contentRef.current.selection.value.reduce((array, next)=>{
                let curTourn = contentRef.current.selection.tabs.find(i =>i.tournIds.includes(next))
                if(curTourn && !array.includes(curTourn.nameProfileId)){
                    array.push(curTourn.nameProfileId)
                }
                return array
            },[])
            if(curProfileIds.length > 1 || !hlTournMatchMenu?.includes(curProfileIds[0]) ){
                sessionStorage.removeItem('hlTournMatchMenu')
            }
            const tournId = contentRef.current.selection.tabs.find(i => i.tournIds.includes(contentRef.current.selection.value[0]) || i.nameProfileId == contentRef.current.selection.value[0])?.nameProfileId
            let tournAll = contentRef.current.selection.tabs.length > 0 && contentRef.current.selection.tabs.every(i => i.tournIds.some(item => contentRef.current.selection.value.includes(item)))
            contentRef.current.selection.tournAll = tournAll
            if(tournId) hash =  `tournid=${tournAll ? 'all':tournId}`
        }
        else if(type === 'DATE'){
            sessionStorage.removeItem('hlTournMatchMenu')
            hash =  `date=${contentRef.current.selection.value[0]}`
        }
        if(hash && !isTournamentPage(contentRef.current.page) && !isMatchResultPage(contentRef.current.page) && !isTournResultPage(contentRef.current.page) && !isPmPoolResultPage(contentRef.current.page)){
            navigate(window.location.pathname+`?${hash}`+window.location.hash)
        }
    },[content.selection.value, content.page])

    useEffect(()=>{
        let value = []
        let type = 'ALL'
        let searchObj = window.location.search.split('?').find(item => {
            let [key, value] = item.split('=')
            return ['date','tournid'].includes(key?.toLowerCase())
        })?.split('=')
        if(searchObj){
            const key = searchObj[0].toLowerCase()
            if(key === 'tournid'){
                type = 'TOURN'
                value.push(searchObj[1])
            }else if(key == 'date'){
                type = 'DATE'
                value.push(searchObj[1])
            }
            contentRef.current.selection.value = value
            contentRef.current.selection.type = type

        }
    },[])

    // manual refresh
    const reloadPage = (byPassPause) => {
        if(!document.querySelector(windowSize.isMobileSize ? '#refreshButton' : '.refreshIcon')?.classList.contains('rotate')) {
            document.querySelector(windowSize.isMobileSize ? '#refreshButton' : '.refreshIcon')?.classList?.add('rotate')
            setTimeout(() => {
                document.querySelector(windowSize.isMobileSize ? '#refreshButton' : '.refreshIcon')?.classList?.remove('rotate')
            }, 1200)
        }
        if ( !byPassPause && contentRef.current.pauseManualRefresh ) {
            return;
        }

        switch ( contentRef.current.page ) {
            case "CASHOUT":
                if ( isLogonHigh() && isEarlySettlementEanbled(globalContext) && !globalContext.globalRef.current.isSendingBet ) {
                    globalContext.esHook.getOpenBetsRequest(0,  false, pushData);
                }
                break;
            default:
                refreshData.reload();
                break;
        }
        contentRef.current.pauseManualRefresh = true;
        const curPageRefreshTime = contentRef.current.config[isInplayPage(contentRef.current.page) ? "autoRefreshIntervalInplay" : contentRef.current.page == "CASHOUT" ? "esManualRefreshInterval" : "autoRefreshIntervalNormal"]
        setTimeout(() => { contentRef.current.pauseManualRefresh = false }, curPageRefreshTime * 1000);
    }

    useEffect(() => {
        if (content.page == "CASHOUT" && isLogonHigh()) {
            const timer = ms => new Promise(res => setTimeout(res, ms))
            esPageRefreshRef.current.esIntervalFunc = async () => {
                if (isEarlySettlementEanbled(globalContext) && !globalContext.globalRef.current.isSendingBet) {
                    if (content.esGetOpenbet) {
                        if (contentRef.current.pauseManualRefresh) {
                            return;
                        }
                        await globalContext.esHook.getOpenBetsRequest(0, false, pushData);
                        content.esGetOpenbet = false;
                    }
                    else {
                        pushData.pubESInit();
                        await globalContext.esHook.forceUpdate();
                        content.esGetOpenbet = true;
                    }
                }
                await timer(content.config["autoRefreshIntervalES"] * 1000);
                esPageRefreshRef.current.esIntervalFunc?.();
            };
            setTimeout(() => {
                esPageRefreshRef.current.esIntervalFunc?.();
            }, content.config["autoRefreshIntervalES"] * 1000);
        }
        else {
            clearTimeout(esPageRefreshRef.current.esIntervalFunc);
            esPageRefreshRef.current.esIntervalFunc = null;
        }
        return () => {
            clearTimeout(esPageRefreshRef.current.esIntervalFunc);
            esPageRefreshRef.current.esIntervalFunc = null;
        }
    }, [content.page, isLogonHigh()]);

    // detect page change
    useEffect(() => {
        if (page == null) {
            navigate(location.pathname + '/home', { replace: true });
            return;
        }

        let curPage = page.toUpperCase();
        if ( curPage=="ES" ) {
            curPage = "CASHOUT";
        }
        const oldTopic = getFootballTopics(contentRef)
        contentRef.current.betType = curPage === 'HOME' ? 'HAD' : curPage;
        // for handling click top menu FOOTBALL from home page
        if ( (contentRef.current.page==="HOME" && curPage==="HOME") ||
         (contentRef.current.page==="RESULTS" &&curPage==="RESULTS") ||
         (contentRef.current.page==="TOURNRES" &&curPage==="TOURNRES")
         ) {
            contentRef.current.update = Math.random();
        }
        contentRef.current.page = curPage;
        contentRef.current.pageConfig = window.fbPageConfig[contentRef.current.page];
        contentRef.current.isLoading = true;
        contentRef.current.pageNum = 1;
        contentRef.current.isAllUpDtls = false;
        contentRef.current.tournNameProfileId = 'TOURNAMENT' === curPage ? matchId : null;
        contentRef.current.oddsSelection = [];
        resetDateTournTab(contentRef);
        setContent({ ...contentRef.current });
        setTickToCalc(0);
        const disabledCache = oldTopic.length > 0
        pushData.resubscribe(contentRef, disabledCache);

        if ("CASHOUT"===contentRef.current.page) {
            contentRef.current.isLoading = false;
            if ( isLogonHigh() && isEarlySettlementEanbled(globalContext) && !globalContext.globalRef.current.isSendingBet ) {
                globalContext.esHook.getOpenBetsRequest(0, true, pushData);
            }
            setContent({...contentRef.current});
        }
        if(contentRef.current.config?.SingleMatchPages?.includes(contentRef.current.page) && contentRef.current?.listData?.matches && matchId){
            loadMatchQuery({ page: contentRef.current.page, matchIds: [matchId] }, null, {isAllodds: true})
        }

        globalContext.updateGlobalState({
            product: curProduct,
            betType: contentRef.current.betType
        });
    }, [page, matchId, globalContext.jumpFbHomePage]);

    useEffect(() => {
        if ( isLogonHigh() && isEarlySettlementEanbled(globalContext) && !globalContext.globalRef.current.isSendingBet ) {
            globalContext.esHook.getOpenBetsRequest(0, true, pushData);
        }
    }, [isLogonHigh(), globalContext.globalRef.current.isSendingBet])

    const handleChangePage = (url, page, fromES, _matchId) => {
        if ( globalContext.globalRef.current.isLoadingData ) {
            return;
        }
        scrollTo(0, 0);
        let queryString = url.split("?")[1]
        if(queryString){
            sessionStorage.setItem('hlTournMatchMenu', queryString)
            setTimeout(()=>{
                const queryData= queryString.split('&').find(i => ['tournid', 'date'].some(item => i.toLowerCase().includes(item)))
                if(queryData){
                    const [key, value] = queryData.split('=')
                    let filterType = key.toLowerCase() == 'tournid' ? 'TOURN' : 'DATE'
                    if(key.toLowerCase() == 'tournid' && !isNaN(value)){
                        let newPage = page.split('?')[0]?.toUpperCase()
                        let matchList = getDisplayMatches(contentRef.current.rawMatchList, newPage, contentRef.current.offset)
                        let tournIds = getQueryTorunIds(matchList, contentRef.current, value);
                        contentRef.current.listData= {matches: matchList}
                        handleSelectionChange(filterType, tournIds, value);
                    }
                    
                }
            })
        }
        let isSamePageClick = page.split('?')[0]?.toUpperCase?.()?.split?.('/')?.[0]===contentRef.current.page ;
        if(contentRef.current.config?.SingleMatchPages?.includes(page?.toUpperCase().split('/')[0]) && isSamePageClick){
            let newMatchId = url?.toUpperCase().split('?')[0].split('/')[4]
            let oldMatchId = window.location.pathname.toUpperCase().split('/')[4]
            if(newMatchId && newMatchId !=  oldMatchId){
                isSamePageClick = false
                if(content.selection.type == 'DATE'){
                    getQueryMatchIdForDateTab(contentRef.current.listData.matches, contentRef.current.offset, contentRef.current, newMatchId)
                }else if( content.selection.type == 'TOURN'){
                    let cMatch = contentRef.current?.listData?.matches?.find(item => item.id === newMatchId)
                    if(cMatch){
                        getQueryMatchIdForTournTab(contentRef.current.listData.matches, contentRef.current, newMatchId, cMatch.tournament.nameProfileId )
                    }
                }
            }
        }
        let curPage = page?.toUpperCase().split('/')[0];
        let isTournPage = ['tournament', 'tournres'].includes(page?.toLowerCase().split('/')[0]);
        let isResultPage = contentRef.current.betType === 'RESULTS'
        if ( curPage=="ES" ) {
            curPage = "CASHOUT";
        }
        if( (contentRef.current.page === 'MIXALLUP_DETAIL' && curPage === 'MIXALLUP')
          || (contentRef.current.page==="CASHOUT" && curPage==="CASHOUT") ){
            isSamePageClick = true
        }
        url = isSamePageClick ? url + window.location.search : url;

        (!isSamePageClick || isTournPage || isResultPage) &&  navigate(url, { state: { matchId: _matchId } });

        if (contentRef.current.config.MixAllUpDetailPages.includes(contentRef.current.page)) {
            contentRef.current.allupSelection = [];

        }
        if(contentRef.current.selection.value.length > 0 && contentRef.current.selection.value[0] === 'OTHER'){
            contentRef.current.selection.value = []
        }

        contentRef.current.oddsSelection = [];
        contentRef.current.matchData = null;
        contentRef.current.tournData = null;
        contentRef.current.allupCalData = null;
        contentRef.current.isAllUpDtls = false;
        contentRef.current.fromES = fromES==true;
        contentRef.current.pageNum = 1;
        contentRef.current.mixAllUpUnitBet = parseInt(getPersonalSettings('football', 'ALUP'), 10);
        contentRef.current.mixAllUpFormula = '';

        contentRef.current.isLoading = true;
        if ( isSamePageClick ) {
            contentRef.current.update = Math.random();
            if(contentRef.current.page === 'MIXALLUP_DETAIL'){
                contentRef.current.betType = curPage;
                contentRef.current.page = curPage;
                contentRef.current.pageConfig = window.fbPageConfig[contentRef.current.page];
            }
            else if ("CASHOUT"===contentRef.current.page) {
                contentRef.current.isLoading = false;
                if ( isLogonHigh() && isEarlySettlementEanbled(globalContext) && !globalContext.globalRef.current.isSendingBet && !contentRef.current.pauseManualRefresh ) {
                    contentRef.current.pauseManualRefresh = true;
                    const curPageRefreshTime = contentRef.current.config["esManualRefreshInterval"]
                    setTimeout(() => { contentRef.current.pauseManualRefresh = false }, curPageRefreshTime * 1000);
                    globalContext.esHook.getOpenBetsRequest(0, true, pushData);
                }
                setContent({...contentRef.current});
            }
        }
        setContent({ ...contentRef.current });
    }

    const updateContent = (name, value) => {
        contentRef.current[name] = value;
        setContent({ ...contentRef.current });
    }

    const clearOddsSelection = (cancelTickNum = 999, addBetList = []) => {
        let selectionList = contentRef.current.oddsSelection;
        if (cancelTickNum == 999) {
            selectionList = selectionList.map(sel => {
                sel.checked = false;
                return sel;
            });
        } else {
            let num = 0;
            for (let i = 0; i < addBetList.length; i++) {
                const item = addBetList[i];
                selectionList = selectionList.map(sel => {
                    if (sel.id == item.id) {
                        sel.checked = false;
                    }
                    return sel;
                });
                num++;
                if (num == cancelTickNum) {
                    break;
                }
            }
        }
        setContent({ ...contentRef.current })
    }

    const handleClickOddsCheckbox = (state) => {
        if (contentRef.current.config.MixAllUpDetailPages.includes(contentRef.current.page)) {
            let newSelection = [];
            state.reverse().map((_v) => {
                let isBetNotAvailable = newSelection.filter(x => x.matchId == _v.matchId && x.oddsType != _v.oddsType).length > 0;
                if (!isBetNotAvailable && _v.oddsType != null && _v.oddsType !== 'MIXALLUP') {
                    newSelection.push(_v)
                }
            })
            contentRef.current.oddsSelection = [...newSelection]
        } else {
            contentRef.current.oddsSelection = [...state]
            contentRef.current.isLoading = false;
        }
        setContent({ ...contentRef.current });

        // 以下代码是因为下面的useEffect需要等较长时间才能执行到，不能及时给到用户反馈
        let checkedLen = content?.oddsSelection?.filter((item) => {
            return item.checked
        })?.length
        if((checkedLen && checkedLen > 0)) {
            globalContext.setBottomMenuAddSlipButtonDisable(false)
        } else {
            globalContext.setBottomMenuAddSlipButtonDisable(true)
        }
    }

    useEffect(() => {
        let checkedLen = content?.oddsSelection?.filter((item) => {
            return item.checked
        })?.length
        if((checkedLen && checkedLen > 0)) {
            globalContext.setBottomMenuAddSlipButtonDisable(false)
        } else {
            globalContext.setBottomMenuAddSlipButtonDisable(true)
        }
    }, [content?.oddsSelection])

    useEffect(()=>{
        handleClearAllupSelection();
    },[globalContext.jumpFbHomePage])

    const handleClearAllupSelection = () => {
        if (contentRef.current.config.MixAllUpDetailPages.includes(contentRef.current.page)) {
            contentRef.current.oddsSelection = [];
        } else {
            contentRef.current.allupCalData = null;
            contentRef.current.oddsSelection = [];
        }
        setContent({ ...contentRef.current });
    }

    const updateMixAllUpContent = (data) => {
        if ( data.alupFormula !== undefined )
            contentRef.current.mixAllUpFormula = data.alupFormula;
        if ( data.unitBet )
            contentRef.current.mixAllUpUnitBet = data.unitBet;

        setContent({ ...contentRef.current });
    }

    const  [scrollBottom, setScrollBottom] = useState()
    const [toastApi, toastContent] = Toast.useToast({ max: 3, position:'fixed' });
    const handleOnClickOddsValue = (data) => {
        if (data == null || data.oddsVal === '---' || contentRef.current.config.MixAllUpDetailPages.includes(contentRef.current.page)) {
            return;
        }
        contentRef.current.allupCalData = {...data};
        setContent({ ...contentRef.current });
        const scrollTarget = document.querySelector('.StandardPage-scrollbars > div')
        setScrollBottom(scrollTarget.scrollHeight - scrollTarget.scrollTop)
        setTickToCalc(Math.random());
        // toastApi({ message:  t('LB_ALL_UP_ADDED')});
    }

    // PRJ1027-6974 有問題，技術上暫沒有找到實現方法
    // useEffect(() => {
    //     if(tick > 0) {
    //         setTimeout(() => {
    //             const scrollTarget = document.querySelector('.StandardPage-scrollbars > div')
    //             if(scrollTarget){
    //                 scrollTarget.scrollTop = scrollTarget.scrollHeight - scrollBottom
    //             }
    //         }, 0);
    //     }
    // }, [tick])

    const [allupCalIntersectionRatio, setAllupCalIntersectionRatio] = useState(1)
    const [mixAllupControllerIntersectionRatio, setMixAllupControllerIntersectionRatio] = useState(1)
    const fbTopBetslip = useRef();
    useIntersectionObserver(fbTopBetslip.current, setAllupCalIntersectionRatio)

    if (contentRef.current.pageConfig == null) {
        return <Loading/>;
    }
    else {
        const state = {
            Product: curProduct,
            lang: i18n.language,
            type: contentRef.current.betType,
            page: contentRef.current.page?.toUpperCase(),
            poolDefined: true,
            windowSize: windowSize,
            onClickOddsCheck: handleClickOddsCheckbox,
            updateContent: (name, val) => { updateContent(name, val) },
            onPageChange: (url, page, fromES, matchId) => { handleChangePage(url, page, fromES, matchId) },
            reloadPage: () => { reloadPage() },
            onSelectionChange: (type, value, isOther) => { handleSelectionChange(type, value, isOther) },
            onAllupNext: () => { handleAllupNextBtnClick() },
            onMixAllUpContentUpdate: (data) => { updateMixAllUpContent(data) },
            clearAllupSelection: () => { handleClearAllupSelection() },
            onClickOddsValue: (data) => { handleOnClickOddsValue(data) },
            onClickSearchResult: (startDt, endDt, type, teamId) => { handleOnClickSearchResult(startDt, endDt, type, teamId) },
            fbOddsTableRef: fbOddsTableRef,
            setLoadMoreLoading,
            toastApi
        }
        const config = contentRef.current.pageConfig;
        const poolDefined = isPoolDefined(content);
        const isSearching = content?.selection?.type === 'SEARCH'
        const showNoResults = isSearching && !content?.matchData?.matches?.length && content?.pageConfig?.showSearchTab && !!content?.listData?.matches?.length
        return (

            <FootballContext.Provider value={{ content, contentRef, setContent, state, clearOddsSelection, loadMoreLoading, tickToCalc, pushData, webTVData, betTypeMessages}} >
                <div >
                    <Helmet>
                        <title>{t('LB_FOOTBALL_TITLE')}</title>
                        <link rel="manifest" href={i18n.language=="en" ? "/manifest/manifest_fbE.json" : "/manifest/manifest_fb.json"} />
                        <meta name="description" content={t('LB_FOOTBALL_DESC')} />
                    </Helmet>

                    {/**************************Important Notice******************************/}
                    <ImportantNotice product={curProduct} enable={config?.showImportantNotice} />
                    <div className="fbMain">


                        {/**************************Banner Ad******************************/}
                        <Banner {...state} enable={config.showPromoBanner} />


                        {/***********************Top Navigation Menu***********************/}
                        {!windowSize.isMobileSize && <TopMenu {...state} />}


                        <div className="fbMiddle">
                            {/***********************Left Navigation Menu***********************/}
                            {!windowSize.isMobileSize && <LeftMenu {...state} />}


                            {/***********************Main Table***********************/}
                            <div className={`fbOddsTable ${state.windowSize?.isMobileSize ? 'mobile' : ''}`} id="fbOddsTable" ref={fbOddsTableRef}>
                                {!contentRef.current.isApiErr ? <>
                                <header className={`${contentRef.current.betType} ${contentRef.current.page}`} >
                                    <FootballTitle isPoolDefined={poolDefined} />
                                    {config.showAddToSlip && poolDefined && <div ref={fbTopBetslip} className="fb-top-betslip"><WebTVIcon /><AddSlipBtn /></div>}
                                    {!state.windowSize?.isMobileSize && (poolDefined || showNoResults)  && <AllupCalculator setAllupCalIntersectionRatio={setAllupCalIntersectionRatio}  enable={config.showTopAllupCal} />}
                                    {!state.windowSize?.isMobileSize &&<MixAllupController setMixAllupControllerIntersectionRatio={setMixAllupControllerIntersectionRatio} />}
                                    <DateTournSelection fbSearchHooks={fbSearchHooks} showNoResults={showNoResults}/>
                                    {state.windowSize?.isMobileSize &&<Sticky innerZ={200} top={'.date-competition-selection'}><MixAllupController /></Sticky>}
                                    {state.windowSize?.isMobileSize && poolDefined && !content.isLoading  && contentRef.current.config.SingleMatchPages.includes(contentRef.current.page) && <AllOddsMobileMatchInfo state={state} content={content} t={t} />}
                                    {state.windowSize?.isMobileSize && (poolDefined || showNoResults) && <AllupCalculator enable={config.showTopAllupCal} />}
                                    {toastContent}
                                </header>
                                <PageRoute isPoolDefined={poolDefined} fbSearchHooks={fbSearchHooks}/>
                                {config.showBottomAddSlip && poolDefined && <FbBottomBetslipSticky height={44} intersectionRatio={allupCalIntersectionRatio} windowSize={windowSize}>
                                    <div className="fb-bottom-betslip">
                                        <AddSlipBtn />
                                    </div>
                                </FbBottomBetslipSticky>
                                }
                                {
                                    contentRef.current?.pageConfig?.showMixAllupControl ? <FbBottomBetslipSticky height={48} intersectionRatio={mixAllupControllerIntersectionRatio} windowSize={windowSize}>
                                        <MixAllupController />
                                    </FbBottomBetslipSticky> : null
                                }
                                </>
                                :
                                    <div className='noInfoTip'>{t('LB_RC_JTC_NOINFO')}</div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                
                <MoblieHomePageTooltips prod={'football'} page={content.page}/>
            </FootballContext.Provider>
        )
    }
}

export default FootballPage;
import './index.scss'

const Tab = ({list, label='label', selected ='',change=()=>{}})=>{
    const tabChange =(Item,index )=>{
        console.log(Item,index, 'Item,index')
        change(Item,index)
    }
    return <div className='commonTabs' id='FBMatchType_Section'>
        {list.map(((Item, index)=>{
            return <div className={`commonTab ${selected === Item?.key ? 'active' : '' }`} onClick={tabChange.bind(null,Item,index)}>{Item[label]}</div>
        }))}
    </div>
}
export default Tab
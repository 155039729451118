import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pad } from '../../Common/home-common';
import MoreButton from './MoreButton'

const FGSTable = ({foPool, isMobile, getOddsVal ,DefaultDisplayumber}) => {

    const { t, i18n } = useTranslation();
    const [isShow,setIsShow] = useState(DefaultDisplayumber!=undefined) 
    const [showStatus,setShowStatus] = useState(false)
    let oddsLines = [];
    useEffect(()=>{
        setIsShow(DefaultDisplayumber!=undefined)
    },[DefaultDisplayumber])
    const line = foPool.lines[0];
    let homeCombs = []
    let awayCombs = []
    line.combinations.forEach(comb => {
        if(!['AVAILABLE'].includes(comb.status)) return
        if(comb.str[0] == '1'){
            homeCombs.push(comb)
        }else if(comb.str[0] == '2'){
            awayCombs.push(comb)
        }
    });
    homeCombs = homeCombs.sort((a, b) => a.currentOdds - b.currentOdds)
    awayCombs = awayCombs.sort((a, b) => a.currentOdds - b.currentOdds)

    let playerSize = Math.max(homeCombs.length, awayCombs.length) - 1
    
    for (let i = 0; i <= playerSize; i++) {
        let homeComb = homeCombs[i]?.str;
        let awayComb = awayCombs[i]?.str;
        let homeOdds = homeCombs[i];
        let awayOdds =  awayCombs[i];

        if (homeOdds == null && awayOdds == null) {
            continue;
        }

        let hNo = null;
        let hName = null;
        let aNo = null;
        let aName = null;

        if ( homeOdds != null ) {
            hNo = homeOdds.selections?.[0]?.str;
            hName = homeOdds.selections?.[0]?.['name_' + i18n.language];
        }
        if ( awayOdds != null ) {
            aNo = awayOdds.selections?.[0]?.str;
            aName = awayOdds.selections?.[0]?.['name_' + i18n.language];
        }

        oddsLines.push(<div className={`match-row${isMobile ? '-m' : ''}`}>
            <div className='odds'>
                <div>{hNo} {hName}</div>
                <span></span>
                {homeOdds && getOddsVal(line, homeComb, isMobile)}
            </div>
            <div className='odds'>
                <div>{aNo} {aName}</div>
                <span></span>
                {awayOdds && getOddsVal(line, awayComb, isMobile)}
            </div>
        </div>)
    }
    let noScorerComb = '000';
    let noScorerOdds = line.combinations.filter(x => x.str === noScorerComb)[0];
    let nNo = noScorerOdds?.selections?.[0]?.str;
    if ( nNo=="000")
        nNo = "00";
    let nName = noScorerOdds?.selections?.[0]?.['name_' + i18n.language];
    if( !nName || nName== "" ) {
        nName = t('LB_FB_FGS_NOFIRSTSCORER');
    }

    oddsLines.push(<div className={`match-row${isMobile ? '-m' : ''} zero`}>
        <div className='odds'>
            <div>{nNo} {nName}</div>
            <span></span>
            {getOddsVal(line, noScorerComb, isMobile)}
        </div>
    </div>)

    return <div className='match-row-container match-table FGS'>
        {isShow ? oddsLines.slice(0,!showStatus ? DefaultDisplayumber : -1) : oddsLines}
        {isShow && <MoreButton ShowLessText="hide" More="more" MoreButtonClick={()=>{setShowStatus(!showStatus)}} expand={showStatus}></MoreButton>}
        </div>;
}
export default FGSTable
import './index.scss'
const Table =({columns=[],data=[]})=>{
    return <table className="jcbwTable">
        <thead>
            <tr>
                {columns.map(i=>{
                    return <th>{i.title}</th>
                })}
                
            </tr>
        </thead>
        <tbody>
            {data.map(rowData=>{
                return <tr>
                    {columns.map(i=>{
                        if (typeof i.onCell === 'function') {
                            return <td>{i.onCell(rowData)}</td>
                        }
                        return rowData[i.key]
                    })}
                </tr>
            })}
        </tbody>
    </table>
}
export default Table
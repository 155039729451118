import React, { useContext, useState } from 'react';
import Tabs from '../Tabs';
import Table from '../Table';
import { FootballContext } from '../../../Home/FootballPage';
import { useTranslation } from 'react-i18next';
import FGSTable from './../FGSTable';

const GSTabTable =({foPool = [],getOddsVal, language})=>{
    const context = useContext(FootballContext);
    const { t } = useTranslation();
    console.log(foPool, 'foPool')
    const [list, setList] = useState(['GS',...window.fbConfig?.GSPools].map(i=>{
        return {
            label: t('LB_FB_TITLE_'+i),
            key: i
        }
    }))
    const [selected, setSelected] = useState("GS")
    const getSelections = (foPools=[]) =>{
        let foPool = foPools.find(pool => pool.oddsType == 'FGS') || foPools.find(pool => pool.oddsType == 'LGS')
        if(!foPool) return
        console.log(foPool)
        let combinations = foPool.lines[0].combinations.filter(comb => comb.status == 'AVAILABLE')
        let sels = combinations.sort((a,b) =>{
            if(a.str == '000') return 1
            if(b.str == '000') return -1
            if(a.currentOdds != b.currentOdds) return a.currentOdds - b.currentOdds
            return a.str - b.str
        })
        console.log(sels)
        return sels
    }
    const [tableData, setTableData] = useState(getSelections(foPool))
    const change = (item,index)=>{
        setSelected(item.key)
    }
    const columns = [
        {
          title:t('LB_FB_GSTABLE_GS'),
          onCell: (param)=>{
            console.log(param, 'param')
            const selection= param.selections[0]
            const abbr = ['','H', 'A']
            return `(${abbr[selection.str[0]]}) ${selection.str} ${selection['name_'+language]}`
          }
        },
        {
            title: t('LB_FB_GSTABLE_FGS'),
            onCell:()=>{
                return <div>222</div>
            }
        },
        {
            title: t('LB_FB_GSTABLE_AGS'),
            onCell:()=>{
                return <div>33</div>
            }
        },
        {
            title: t('LB_FB_GSTABLE_LGS'),
            onCell:()=>{
                return <div>444</div>
            }
        }

    ]
    let isMobile = context.state.windowSize.isMobileSize;
    return <>
        <Tabs list={list} selected={selected} change={change}>
            {selected === 'GS' && <Table data={tableData} columns={columns}></Table>}
            {selected === 'FGS' && <FGSTable foPool={foPool[0]} isMobile={isMobile} getOddsVal={getOddsVal}/>}
            {selected === 'AGS' && <FGSTable foPool={foPool[0]} isMobile={isMobile} getOddsVal={getOddsVal}/>}
            {selected === 'LGS' && <FGSTable foPool={foPool[0]} isMobile={isMobile} getOddsVal={getOddsVal}/>}
        </Tabs>
    </>
}
export default GSTabTable